import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"

class Unsubscribe extends React.Component {
  componentDidMount() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const userEmail = urlParams.get('userEmail')
    const type = urlParams.get('type')

    if (userEmail !== undefined && userEmail !== '' && type !== undefined && type !== '') {
      fetch(`https://us-central1-linksaber-97603.cloudfunctions.net/api/v1/user/emailPermission?userEmail=${userEmail}&${type}=false`, {
        method: 'POST',
        body: {}
      })
    }
  }

  render() {
    return (
      <Layout>
        <Header/>

        <p className="section-body text-gray-400">
          Unsubscribed successfully!<br/><br/>
          Even though it's very sad to see you go :(<br/>
          Remember: you can always restart your digests from the settings screen in the Linksaber app!<br/>
        </p>
      </Layout>
    )
  }
}


export default Unsubscribe